import { z } from 'zod'
import { STATUSES } from '@/consts'
import { cancerKorFilterSchema } from '@/types/cancer'
import type { CancerType } from '@/types/recommend'
import { contentSchema } from '@/types/recommend'

export const Status = z.enum(STATUSES)

const schema = z.object({
  results: z.array(
    z.object({
      status: Status,
      contents: z.array(contentSchema),
    }),
  ),
})

export const mainAtomSchema = z.object({
  hasMainViewed: z.boolean(),
  cancerType: cancerKorFilterSchema.optional(),
})

export type MainListData = z.infer<typeof schema>
export type StatusType = z.infer<typeof Status>

export type Filters = {
  cancerType: CancerType
  status: StatusType
  interestkeys: string
}
