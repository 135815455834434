import useSWR from 'swr'
import { EventStatus } from '@/types/event'
import { handleCustomError } from '@/utils/httpClient/handleCustomError'
import { handleSWR } from '@/utils/httpClient/handleSWR'

/**
 * 주어진 이벤트 ID에 대한 이벤트 상태를 가져오기 위해 useSWR을 사용하는 커스텀 훅입니다.
 *
 * @param {string} eventID - 이벤트의 ID
 * @returns data: 이벤트 응답 값, isEventAvailable: 이벤트 기간 여부  함수 객체 반환
 */
const useEventStatus = (eventID: string) => {
  const { data } = useSWR<EventStatus>(`event/${eventID}/`, handleSWR, {
    onError: (error) => {
      handleCustomError({ error, ignoreCodes: [404] })
    },
    onErrorRetry: () => {},
  })

  const isEventAvailable = data?.status === 'AVAILABLE'

  return { data, isEventAvailable }
}

export default useEventStatus
