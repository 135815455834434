import clsx from 'clsx'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'outlined' | 'shadowed'
  padded?: boolean
}

const Card = ({
  variant = 'outlined',
  padded = true,
  children,
  ...rest
}: Props) => {
  return (
    <div
      className={clsx(
        'w-full',
        'min-w-[150px]',
        'text-left',
        'rounded-[16px]',
        'bg-white',
        { 'border border-grey-400': variant === 'outlined' },
        {
          'shadow-[0px_1px_16px_0px_rgba(11,3,135,0.10)]':
            variant === 'shadowed',
        },
        {
          'p-0': !padded,
        },
        {
          'p-6': padded,
        },
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Card
