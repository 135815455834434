import { useRouter } from 'next/navigation'
import { useSetAtom } from 'jotai'
import Tags from '@/components/Tags'
import usePVThreshold from '@/hooks/usePVThreshold'
import { mainScrollTopAtom } from '@/stores'
import type { ContentResponse } from '@/types/recommend'
import { getLink } from '@/utils/recommend'
import type { GetLinkProps } from '@/utils/recommend'
import List from '@/v1/List'

const CONTENT_TYPE = {
  GUIDE: '주제별 암 정보',
  QNA: '질문과 답변',
}

/**
 * 메인 추천 리스트 아이템
 */
const Item = ({
  id,
  parent_id,
  cancer,
  category,
  content,
  type,
  tags,
}: ContentResponse) => {
  const { handlePVThreshold } = usePVThreshold()
  const { push } = useRouter()
  const setMainScrollTop = useSetAtom(mainScrollTopAtom)

  const handleClick = ({
    id,
    parent_id,
    type,
    category,
    content,
  }: GetLinkProps) => {
    const contentId = id.toString()

    handlePVThreshold({
      id: contentId,
      onSuccess: () => {
        setMainScrollTop(window.scrollY)
        push(
          getLink({
            id: contentId,
            parent_id,
            type,
            category,
            content,
          }),
        )
      },
      skip: type === 'GUIDE',
    })
  }

  return (
    <List.ItemButton
      key={id}
      px="sm"
      onClick={() => {
        handleClick({
          id: id.toString(),
          parent_id: parent_id ? parent_id.toString() : null,
          type,
          category,
          content,
        })
      }}
      data-ga="main_contents_document"
      className="py-6 group-[.divider]:last:pb-3"
    >
      <p className="text-grey-800 mb-1 prose-p3">
        {cancer} · {CONTENT_TYPE[type]}
      </p>
      <p className="prose-p1 break-keep">
        <b>{content}</b>
      </p>
      <Tags tags={tags} />
    </List.ItemButton>
  )
}

export default Item
