import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import useCancerContentsParam from '@/hooks/useCancerContentsParam'
import { mainAtom } from '@/stores'
import type { Filters } from '../types'

/**
 * 내 정보(암종, 치료단계, 관심사) 또는 드롭다운으로 선택한 암종에 따른 필터링 설정
 * @param setFilters 필터링 설정을 변경하는 함수
 */
const useMyFilter = ({
  setFilters,
}: {
  setFilters: Dispatch<SetStateAction<Filters>>
}) => {
  // 내 정보에 따른 필터링 설정 플래그. 화면 첫 진입 시에만 실행하고 이후에는 무시한다.
  const hasPresetData = useRef(false)

  // 내 정보에 따른 필터링 설정
  const cancerContentsParam = useCancerContentsParam()
  const [{ cancerType: cancerTypeAtom }] = useAtom(mainAtom)
  const setMyFilters = useCallback(() => {
    if (!cancerContentsParam) return

    setFilters((prev) => {
      hasPresetData.current = true
      // 유저가 암종필터를 선택했으면 그걸로 암종 설정
      if (cancerTypeAtom) {
        return {
          status: cancerContentsParam.status,
          interestkeys: cancerContentsParam.interestkeys,
          cancerType: cancerTypeAtom,
        }
      }

      const { cancerType, status, interestkeys } = cancerContentsParam

      // myParam이 변경되지 않았다면 이전 필터를 그대로 사용한다.
      if (
        prev.cancerType === cancerType &&
        prev.status === status &&
        prev.interestkeys === interestkeys
      ) {
        return prev
      }

      return {
        ...prev,
        cancerType,
        status,
        interestkeys,
      } as Filters
    })
  }, [cancerTypeAtom, cancerContentsParam, setFilters])

  // 페이지 로드 시 내 정보에 따른 필터링 설정을 적용한다.
  useEffect(() => {
    if (hasPresetData.current) return

    setMyFilters()
  }, [setMyFilters])

  // 암종 또는 치료단계가 변경되면, 필터를 업데이트 한다.
  useEffect(() => {
    hasPresetData.current = false
  }, [cancerTypeAtom, cancerContentsParam])
}

export default useMyFilter
