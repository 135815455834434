import { useRouter } from 'next/navigation'
import { URL_PATH } from '@/consts'
import Nudge from '@/v1/Nudge'

const HealthInfoNudge = () => {
  const { push } = useRouter()

  return (
    <Nudge
      iconName="heart_plus_fill"
      onClick={() =>
        // 'users/me/health-information' 페이지로 이동합니다.
        // 이 때, URL에 'main=true' 쿼리 파라미터를 추가하여 건강기록을 저장한 후 메인페이지로 돌아올 수 있도록 합니다.
        push(`${URL_PATH.MyCareHealthInfo}?main=true`)
      }
      data-ga="main_healthinfo"
    >
      현재 상태를 알려주시면
      <br />
      <b>맞춤 정보를 제공</b>해 드려요
    </Nudge>
  )
}

export default HealthInfoNudge
