import Link from 'next/link'
import { useSetAtom } from 'jotai'
import useVirtuosoSnapshotReset from '@/hooks/useVirtuosoSnapshotReset'
import { mainScrollTopAtom } from '@/stores'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { Filters } from '../types'

/**
 * 추천 리스트의 카드 헤더
 * - 더보기 링크가 있는 경우와 없는 경우로 나뉜다.
 * - 더보기 링크가 있는 경우, 더보기 링크를 클릭하면 현재 스크롤 위치를 저장한다. 뒤로 가기 시 저장된 위치로 이동한다.
 * @param cancerType 암종
 * @param status 치료단계
 * @param more 더보기 링크 여부
 */
const CardHeader = ({
  cancerType,
  status,
  more,
}: Omit<Filters, 'interestkeys'> & {
  more: boolean
}) => {
  const setMainScrollTop = useSetAtom(mainScrollTopAtom)
  const { resetScroll } = useVirtuosoSnapshotReset()
  const handleClick = () => {
    setMainScrollTop(window.scrollY)
    resetScroll()
  }

  return (
    <h5 className="prose-h5">
      {/* 더보기 링크 */}
      {more ? (
        <Link
          data-ga="main_contents_more"
          className="flex justify-between items-center pb-[18px]"
          href={`/contents/?cancerType=${cancerType}&status=${status}`}
          onClick={handleClick}
        >
          {status}
          <MaterialSymbol name="chevron_right" className="ml-3" />
        </Link>
      ) : (
        <span className="block pb-[18px]">{status}</span>
      )}
    </h5>
  )
}

export default CardHeader
