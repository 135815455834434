import { PropsWithChildren, useEffect, useRef } from 'react'
import Tippy from '@tippyjs/react/headless'
import { Instance, Placement, Props, sticky } from 'tippy.js'
import TooltipComponent from './Tooltip'

const DELAY = 10000

const AutoHideTooltip = ({
  content,
  placement = 'bottom',
  delay = DELAY,
  onHide,
  children,
}: PropsWithChildren<{
  content: string
  placement?: Placement
  delay?: number
  onHide?: () => void
}>) => {
  const tippyjsRef = useRef<Instance<Props> | null>(null)

  useEffect(() => {
    const timerId = setTimeout(() => {
      tippyjsRef.current?.hide()
      onHide?.()
    }, delay)

    return () => clearTimeout(timerId)
  }, [delay, onHide])

  return (
    <Tippy
      plugins={[sticky]}
      sticky={true}
      arrow={true}
      content={content}
      placement={placement}
      offset={[0, 8]}
      zIndex={10}
      appendTo={'parent'}
      showOnCreate={true}
      touch={false}
      hideOnClick={false}
      render={(_, __, instance) => {
        if (instance) tippyjsRef.current = instance
        return <TooltipComponent content={content} placement={placement} />
      }}
    >
      <button type="button">{children}</button>
    </Tippy>
  )
}

export default AutoHideTooltip
