import { S3_IMAGE_URL } from '@/config'

export const SWIPER_INDICATOR_STYLE = [
  'absolute',
  '!bottom-2.5',
  '!left-[initial]',
  '!right-2',
  'z-10',
  '!w-fit',
  'text-xs',
  'leading-[1.5]',
  'text-center',
  'rounded-[58px]',
  'bg-[#5D6062B2]',
  'text-white',
  'px-1.5',
  'py-0.5',
  'opacity-70',
].join(' ')

export const BANNER_DATA = [
  {
    id: 0,
    imgSrc: `${S3_IMAGE_URL}/main_rolling_banner_hospital.png`,
    imgAlt: '병원 예약',
    gradient: 'bg-main-banner-hospital-gradient',
    gaSelector: 'main_appointments_first',
    title: `막막한 병원 예약\n루닛케어에 맡기기`,
  },
  {
    id: 1,
    imgSrc: `${S3_IMAGE_URL}/plus/rolling_banner_main_report.png`,
    imgAlt: '루닛케어플러스 리포트',
    gaSelector: 'main_plus_banner_report',
    title: `의료진의 맞춤 리포트로\n빠른 회복 관리`,
  },
  {
    id: 2,
    imgSrc: `${S3_IMAGE_URL}/main_rolling_banner_hotline.png`,
    imgAlt: '즉시 무료 상담받기',
    gradient: 'bg-main-banner-hotline-gradient',
    gaSelector: 'main_hotline_banner',
    title: `의료진과 24시간 핫라인\n070-7174-3737`,
  },
  {
    id: 3,
    imgSrc: `${S3_IMAGE_URL}/main_rolling_banner_plus.png`,
    imgAlt: '루닛케어플러스',
    gradient: 'bg-main-banner-plus-gradient',
    gaSelector: 'main_plus_banner',
    title: `암 전문 의료진과\n상담으로 빠른 회복`,
  },
  {
    id: 4,
    imgSrc: `${S3_IMAGE_URL}/main_rolling_banner_calculator.png`,
    imgAlt: '치료비 계산기',
    gradient: 'bg-main-banner-calculator-gradient',
    gaSelector: 'main_calculator',
    title: `내가 부담할 암 치료비\n계산하고 대비하기`,
  },
  {
    id: 5,
    imgSrc: `${S3_IMAGE_URL}/main_rolling_banner_regimens.png`,
    imgAlt: '',
    gradient: 'bg-main-banner-regimens-gradient',
    gaSelector: 'main_regimens_banner', // TODO: 확인
    title: `암 종류별, 진행 상태별\n이럴 땐 이 항암제`,
  },
]
