import { S3_IMAGE_URL } from '@/config'

type Environment = 'develop' | 'staging' | 'production'
type Event = 'event1' | 'event2' | 'event3' | '2024Referral'

// TODO: local를 제외한 나머지 환경에서 이벤트 아이디 값 확인 필요
const EVENT_IDS: Record<Event, Record<Environment, string>> = {
  // event1 - 암 극복 글쓰기 공모전 이벤트
  event1: {
    develop: '2',
    staging: '2',
    production: '2',
  },
  // event2 - 암 콘서트 응모 이벤트
  event2: {
    develop: '1',
    staging: '1',
    production: '1',
  },
  // event3 - 인바디 재휴 이벤트
  event3: {
    develop: '3',
    staging: '3',
    production: '3',
  },
  // 2024.09 추천인 이벤트
  '2024Referral': {
    develop: '6',
    staging: '5',
    production: '5',
  },
}

const env = process.env.NEXT_PUBLIC_ENV as Environment

/**
 * 현재 환경에서 암 극복 글쓰기 공모전 응모 이벤트의 ID입니다.
 */
export const EVENT1_ID = EVENT_IDS['event1'][env]

/**
 * 현재 환경에서 암 콘서트 응모 이벤트의 ID입니다.
 */
export const EVENT2_ID = EVENT_IDS['event2'][env]

/**
 * 현재 환경에서 인바디 재휴 이벤트의 ID입니다.
 */
export const EVENT3_ID = EVENT_IDS['event3'][env]

/**
 * 2024.09 추천인 이벤트 ID
 */
export const REFERRAL_EVENT_ID = EVENT_IDS['2024Referral'][env]

export const SHOULD_EVENT_DRAW = '?event=draw'

export const EVENT_CLOSED_MESSAGE = '이벤트 기간이 종료되었습니다.'

export const APPLIED_MESSAGE = '응모되었습니다'

export const URL_EVENT_PATH = '/events/202310_canceressaycontest'
export const URL_IMAGE_PATH = `${S3_IMAGE_URL}/events/202310`

export const INBODY_EVENT_URL =
  'https://smartstore.naver.com/inbodybwa/products/9384507203?utm_source=lunitcare&utm_medium=main&utm_campaign=inbody_navershopping_20231025'
