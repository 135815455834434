export const DIM_STYLE =
  'fixed inset-0 w-full transition-all ease-in-out z-20 opacity-80 bg-black touch-none'

export const FAB_RIGHT_POSITION =
  'right-[calc((100%_-_768px)_/_2_+_28px)] tablet:right-[24px]'

export const FAB_STYLE = [
  'fixed',
  'flex',
  'h-16 pr-[76px]',
  'z-30',
  FAB_RIGHT_POSITION,
].join(' ')

// 전화 상담하기 버튼
export const CALL_FAB = {
  web: 'bottom-[calc(104px_+_env(safe-area-inset-bottom))]',
  // 모바일 앱에서는 하단 탭바가 있어서 52px만큼 위로 올려야 한다.
  app: 'bottom-[calc(156px_+_env(safe-area-inset-bottom))]',
}

// 질문 작성하기 버튼
export const CREATE_DOCUMENT_FAB = {
  web: 'bottom-[calc(184px_+_env(safe-area-inset-bottom))]',
  // 모바일 앱에서는 하단 탭바가 있어서 52px만큼 위로 올려야 한다.
  app: 'bottom-[calc(236px_+_env(safe-area-inset-bottom))]',
}

export const ASSISTANT_FAB = {
  web: 'bottom-[calc(264px_+_env(safe-area-inset-bottom))]',
  // 모바일 앱에서는 하단 탭바가 있어서 52px만큼 위로 올려야 한다.
  app: 'bottom-[calc(316px_+_env(safe-area-inset-bottom))]',
}
