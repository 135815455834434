'use client'

import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import useToggleBodyScroll from '@/hooks/useToggleBodyScroll'
import FAButton from '@/v1/FAButton'
import MaterialSymbol from '@/v1/MaterialSymbol'
import FloatingButtons from './FloatingButtons'

const SCROLL_THRESHOLD = 80

/**
 * 의료팀에게 질문하기 Floating Action Button
 * 스크롤이 최상단일 때는 확장형이며 스크롤시 축소된다.
 * 클릭 시 의료팀 질문 작성하기와 전화 상담하기 버튼이 노출된다.
 */
const CreateDocumentFAButton = () => {
  const [isExtend, setIsExtend] = useState(true)
  const [isMenuOpened, setIsMenuOpened] = useState(false)

  useToggleBodyScroll(isMenuOpened)

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY
    if (!isExtend && scrollY < SCROLL_THRESHOLD) setIsExtend(true)
    else if (!!isExtend && scrollY >= SCROLL_THRESHOLD) setIsExtend(false)
  }, [isExtend])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return isMenuOpened ? (
    <FloatingButtons onClose={() => setIsMenuOpened(false)} />
  ) : (
    <FAButton
      variant={isExtend ? 'extended' : 'regular'}
      className={clsx({ 'w-[216px] gap-3': isExtend })}
      onClick={() => setIsMenuOpened(true)}
      data-ga="main_fab"
    >
      {isExtend && <>암 관리 시작하기</>}
      <MaterialSymbol
        name="contact_support_fill"
        className="w-8 h-8 fill-white"
      />
    </FAButton>
  )
}

export default CreateDocumentFAButton
