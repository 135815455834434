import { usePathname } from 'next/navigation'
import { URL_PATH } from '@/consts/urls'
import useAuth from '@/hooks/useAuth'
import { isWebview } from '@/utils'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { Header } from '../Header'
import NotificationButton from './NotificationButton'

/**
 * Header의 오른쪽 버튼
 * 웹에서는 메뉴 버튼, 앱에서는 알림 버튼
 * 아직 웹에서 알림 기능이 없어서 이렇게 함. 나중에 수정 필요.
 */
const RightButton = ({ fill = 'grey' }: { fill?: 'white' | 'grey' }) => {
  const isApp = isWebview()
  const { isLoggedIn } = useAuth()
  const pathname = usePathname()

  // 알림버튼 노출기분
  // 1. 공통: 로그인 상태여야 함
  // 2. 앱: 메인과 마이케어 페이지 헤더
  // 3. 웹: 피처 플래그가 활성화된 경우, 마이케어 페이지 헤더
  const hasNotiInApp =
    isApp &&
    (pathname === URL_PATH.Main || (pathname ?? '').startsWith(URL_PATH.MyCare))

  if (isLoggedIn && hasNotiInApp) {
    return <NotificationButton />
  }

  if (isApp) return null

  return (
    <Header.IconButton
      data-ga="header_hamburger"
      isMenu={true}
      icon={
        <MaterialSymbol
          name="menu"
          className={fill === 'white' ? 'fill-white' : 'fill-grey-900'}
        />
      }
      className="pl-2"
    />
  )
}

export default RightButton
