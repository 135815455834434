import { useModal } from '@/hooks/useModal'
import { BottomSheetContent } from '@/v1/Modal/BottomSheet/Content'
import LoginContent from '@/v1/Modal/BottomSheet/Content/LoginContent'
import Nudge from '@/v1/Nudge'

const SignupNudge = () => {
  const { bottomSheetModal } = useModal()

  const handleClick = () => {
    bottomSheetModal.show({
      header: {
        size: 'xs',
      },
      content: (
        <LoginContent
          description={<BottomSheetContent />}
          redirectPath={window.location.pathname}
        />
      ),
    })
  }

  return (
    <Nudge
      iconName="emoji_objects_fill"
      onClick={handleClick}
      data-ga="main_signup"
    >
      나만의 의료팀, 루닛케어가
      <br />
      <b>필요한 정보를 찾아</b>드려요
    </Nudge>
  )
}

export default SignupNudge
