import { Dispatch, SetStateAction, useCallback } from 'react'
import { useSetAtom } from 'jotai'
import { mainScrollTopAtom } from '@/stores'
import type { CancerType } from '@/types/recommend'
import type { Filters } from '../../types'
import Dropdown from './Dropdown'

/*
 * 메인 추천리스트 암종 드롭다운 컴포넌트
 */
const CancerDropdown = ({
  cancerType,
  setFilters,
}: {
  cancerType: CancerType
  setFilters: Dispatch<SetStateAction<Filters>>
}) => {
  const setMainScrollTop = useSetAtom(mainScrollTopAtom)

  const setCancerType = useCallback(
    (cancerType: CancerType) => {
      setFilters((prev) => ({ ...prev, cancerType }))
      setMainScrollTop(0)
    },
    [setFilters, setMainScrollTop],
  )

  return (
    <>
      <div className="shrink-0">
        <Dropdown cancerType={cancerType} setCancerType={setCancerType} />
      </div>
    </>
  )
}

export default CancerDropdown
