import { PropsWithChildren } from 'react'
import clsx from 'clsx'

/**
 * 메인 넛지 배너의 공통 wrapper 컴포넌트
 */
const Wrapper = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={clsx(
        'relative h-[88px]',
        'shadow-[0_2px_16px_0_rgba(46,49,52,0.1)]',
        'rounded-[16px]',
      )}
    >
      {children}
    </div>
  )
}

export default Wrapper
