import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { MaxWidthSize, NestedMarginX, MarginX } from '@/consts'
import type { WrapperProps } from './type'

/**
 * 좌우 여백 및 너비를 설정하는 Wrapper 컴포넌트
 *
 * @param children
 * @param maxWidth  최대 너비 'sm(480)' | 'md(768)' | 'lg(full-width)'
 * @param paddingX 'none' | 'sm (px-6)'
 * @param marginX 'none' | 'center'
 *
 */
const PaddingWrapper = ({
  children,
  maxWidth = 'md', // 기본값을 max-w-[768px]로 설정
  paddingX = 'none',
  marginX = 'center',
}: PropsWithChildren<WrapperProps>) => {
  return (
    <div className={clsx(MaxWidthSize[maxWidth], MarginX[marginX])}>
      <div className={clsx('js-wrapper', NestedMarginX[paddingX])}>
        {children}
      </div>
    </div>
  )
}

export default PaddingWrapper
