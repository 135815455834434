import Banner from '@/components/RollingBanner/Banner'
import { BANNER_DATA } from '@/components/RollingBanner/consts'
import { HOTLINE_TELEPHONE } from '@/consts'
import Wrapper from './Wrapper'

const BANNER_INDEX = 2

const HotlineNudge = () => {
  return (
    <Wrapper>
      <Banner
        imgSrc={BANNER_DATA[BANNER_INDEX].imgSrc}
        gradient={BANNER_DATA[BANNER_INDEX].gradient}
        imgAlt={BANNER_DATA[BANNER_INDEX].imgAlt}
        gaSelector="main_hotline_second"
        title={BANNER_DATA[BANNER_INDEX].title}
        onClick={() => {
          window.location.href = `tel:${HOTLINE_TELEPHONE}`
        }}
      />
    </Wrapper>
  )
}

export default HotlineNudge
