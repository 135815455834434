import { ReactNode } from 'react'
import Link from 'next/link'
import Card from '@/v1/Card'

export interface QuickLinkProps {
  icon: ReactNode
  title: string
  description: string
  href: string
}

const QuickLink = ({ icon, title, description, href }: QuickLinkProps) => {
  return (
    <Link href={href} className="block" data-ga="main_services">
      <Card variant="shadowed">
        <p className="mb-0.5 text-grey-600 prose-p3">{title}</p>
        <h6 className="whitespace-pre-wrap text-grey-800 mb-3 prose-h6">
          {description}
        </h6>
        <div className="flex justify-end">{icon}</div>
      </Card>
    </Link>
  )
}

export default QuickLink
