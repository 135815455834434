import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { S3_IMAGE_URL } from '@/config'
import { URL_PATH } from '@/consts'
import useEventStatus from '@/hooks/useEventStatus'
import { REFERRAL_EVENT_ID } from '../Events/202310/consts'

const ReferralEventBanner = () => {
  const { isEventAvailable } = useEventStatus(REFERRAL_EVENT_ID)
  const { push } = useRouter()

  if (!isEventAvailable) return null

  return (
    <div
      className="bg-aqua-100 rounded-2xl p-5 flex justify-between items-center w-full"
      role="button"
      data-ga="main_referral2024_banner"
      onClick={() => push(URL_PATH.ReferralEvent)}
    >
      <h2 className="prose-p2 font-bold">
        소중한 사람도 지키고
        <br />
        응원금도 받고!
      </h2>
      <Image
        alt="네이버 페이 로고"
        src={`${S3_IMAGE_URL}/naver_pay.png`}
        width={88}
        height={40}
      />
    </div>
  )
}

export default ReferralEventBanner
