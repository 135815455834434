import React from 'react'
import { isWebview } from '@/utils'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { Header } from './Header'
import RightButton from './RightButton'

interface MainHeaderProps {
  onClick: () => void
}

export const MainHeader = ({ onClick }: MainHeaderProps) => {
  return (
    <Header>
      <Header.Logo />
      <div className="flex h-full">
        <Header.IconButton
          data-ga="header_search"
          icon={<MaterialSymbol name="search" />}
          className={isWebview() ? 'pr-4' : 'pr-2'}
          onClick={onClick}
        />

        <RightButton />
      </div>
    </Header>
  )
}
