import { useRef, useLayoutEffect } from 'react'
import { useAtomValue } from 'jotai'
import { mainScrollTopAtom } from '@/stores'

/**
 * mainScrollTop atom 값을 사용하여 스크롤 위치를 유지한다.
 * 스크롤 상태가 0이거나, 데이터 로딩 중에는 스크롤을 발생시키지 않는다.
 */
const usePersistScrollPosition = ({ isLoading }: { isLoading: boolean }) => {
  // onScroll에서 클로저 때문에 isLoading 값이 업데이트 되지 않는 문제를 해결하기 위해 추가한 ref
  const isLoadingRef = useRef(isLoading)
  const mainScrollTop = useAtomValue(mainScrollTopAtom)

  useLayoutEffect(() => {
    isLoadingRef.current = isLoading
  }, [isLoading])

  useLayoutEffect(() => {
    const onScroll = () => {
      if (isLoadingRef.current) return
      if (mainScrollTop === 0) return
      window.scrollTo(0, mainScrollTop)
    }

    requestAnimationFrame(onScroll)
  }, [isLoading, mainScrollTop])
}

export default usePersistScrollPosition
