'use client'

import { useCallback, useEffect } from 'react'
import { useAtomValue } from 'jotai'
import AppDownloadBannerWrapper from '@/components/AppDownloadBanner/AppDownloadBannerWrapper'
import CreateDocumentFAButton from '@/components/CreateDocumentFAButton'
import { Divider } from '@/components/Divider'
import Footer from '@/components/Footer'
import MainBottomSheet from '@/components/MainBottomSheet'
import { useModal } from '@/hooks/useModal'
import useSearchModal from '@/hooks/useSearchModal'
import { mainBottomSheetAtom } from '@/stores'
import { MainHeader } from '@/v1/Header/MainHeader'
import PaddingWrapper from '@/v1/Layout/PaddingWrapper'
import List from './List'
import Menu from './Menu'
import HotlineNudge from './Nudges/Hotline'
import ReferralEventBanner from './ReferralEventBanner'

const Main = () => {
  const { openSearchModal } = useSearchModal()
  const mainBottomSheet = useAtomValue(mainBottomSheetAtom)
  const { bottomSheetModal } = useModal()

  const showCarePlusBottomSheet = useCallback(() => {
    bottomSheetModal.show({
      content: <MainBottomSheet />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!mainBottomSheet) return
    showCarePlusBottomSheet()
  }, [mainBottomSheet, showCarePlusBottomSheet])

  return (
    <>
      <AppDownloadBannerWrapper>
        <MainHeader onClick={openSearchModal} />
      </AppDownloadBannerWrapper>
      <div className="mt-2 px-md">
        <ReferralEventBanner />
      </div>
      <PaddingWrapper paddingX="sm">
        <div className="py-md">
          <Menu />
        </div>
      </PaddingWrapper>
      <Divider className="border-grey-100 border-[5px]" />
      <List />
      <PaddingWrapper paddingX="sm">
        <HotlineNudge />
      </PaddingWrapper>
      <Footer />
      <CreateDocumentFAButton />
    </>
  )
}

export default Main
