import { useState } from 'react'
import { useAtomValue } from 'jotai'
import useSWR from 'swr'
import { Divider } from '@/components/Divider'
import { API_PATH, Statuses } from '@/consts'
import useAuth from '@/hooks/useAuth'
import { mainAtom } from '@/stores'
import { handleSWR } from '@/utils/httpClient/handleSWR'
import Card from '@/v1/Card'
import PaddingWrapper from '@/v1/Layout/PaddingWrapper'
import ListComponent from '@/v1/List'
import { CalculatorNudge, HospitalNudge } from '../Nudges'
import AppointmentNoteNudge from '../Nudges/AppointmentNote'
import PlusNudge from '../Nudges/Plus'
import QuickLinks from '../QuickLinks'
import { Filters, MainListData } from '../types'
import CancerDropdown from './CancerDropdown'
import CardHeader from './CardHeader'
import Item from './Item'
import Tooltip from './Tooltip'
import useMyFilter from './useMyFilter'
import usePersistScrollPosition from './usePersistScrollPosition'

const DISPLAY_LIST_COUNT = 3
const INITIAL_FILTERS: Filters = {
  cancerType: '전체',
  status: Statuses.예방,
  interestkeys: '',
} as const

/**
 * 메인 페이지의 컨텐츠 리스트를 렌더링한다.
 */
const List = () => {
  const { isLoggedIn } = useAuth()
  const { cancerType: cancerTypeAtom } = useAtomValue(mainAtom)
  const [filters, setFilters] = useState<Filters>(INITIAL_FILTERS)
  // 비로그인인 경우 mainAtom에 저장된 암종으로 설정한다
  const cancerType = isLoggedIn ? filters.cancerType : cancerTypeAtom ?? '전체'
  const params = `${new URLSearchParams({
    cancer: cancerType,
    status_order: filters.status,
  }).toString()}&${filters.interestkeys}`

  const { data, isValidating } = useSWR<MainListData>(
    `${API_PATH.CONTENTS}?${params}`,
    handleSWR,
    {
      revalidateOnFocus: false,
      keepPreviousData: true,
    },
  )

  // 내 정보에 따라 필터링 설정. 화면 첫 진입 시에만 실행한다.
  useMyFilter({ setFilters })
  // 메인 페이지에서 스크롤 위치를 유지한다.
  usePersistScrollPosition({
    isLoading: isValidating,
  })

  return (
    <>
      <PaddingWrapper paddingX="sm">
        <div className="flex mt-6">
          <CancerDropdown cancerType={cancerType} setFilters={setFilters} />
          <Tooltip cancerType={cancerType} />
        </div>
      </PaddingWrapper>

      {data?.results.map((item) => {
        // 컨텐츠가 없는 카드는 노출하지 않는다.
        if (item.contents.length === 0) return
        return (
          <div key={`item-${item.status}`} className="pt-5 px-6">
            {item.status === Statuses.의심 && (
              <div className="pb-5">
                <CalculatorNudge />
              </div>
            )}
            {item.status === Statuses.진단 && (
              <div className="pb-5">
                <HospitalNudge />
              </div>
            )}

            {item.status === Statuses.치료 && (
              <div className="pb-5">
                <AppointmentNoteNudge gaSelector="main_notes_second" />
              </div>
            )}

            {item.status === Statuses.관리 && (
              <div className="pb-5">
                <PlusNudge />
              </div>
            )}

            <Card variant="shadowed">
              <CardHeader
                cancerType={cancerType}
                status={item.status}
                more={item.contents.length > DISPLAY_LIST_COUNT}
              />
              <Divider className="border-grey-100" />

              <ListComponent divider>
                {item.contents.map(
                  (
                    { id, parent_id, cancer, category, content, tags, type },
                    index,
                  ) => {
                    // 리스트에는 최대 3개까지만 보여주고 3개 이상인 경우 content list 페이지로 이동한다.
                    if (index > DISPLAY_LIST_COUNT - 1) return
                    return (
                      <Item
                        key={`content-${id}`}
                        id={id}
                        parent_id={parent_id}
                        cancer={cancer}
                        category={category}
                        type={type}
                        content={content}
                        tags={tags}
                      />
                    )
                  },
                )}
              </ListComponent>
            </Card>
          </div>
        )
      })}
      {data?.results?.length && <QuickLinks />}
    </>
  )
}

export default List
