import Image from 'next/image'
import clsx from 'clsx'

interface Props {
  title: string
  imgSrc: string
  imgAlt: string
  gaSelector: string
  gradient?: string
  onClick: () => void
}

const Banner = ({
  title,
  imgSrc,
  imgAlt,
  gradient,
  gaSelector,
  onClick,
}: Props) => {
  return (
    <div
      onClick={onClick}
      data-ga={gaSelector}
      role="button"
      className={clsx(
        'cursor-pointer',
        'relative',
        'block',
        'w-full',
        'h-full',
        'rounded-2xl',
        'overflow-hidden',
      )}
    >
      <div className="absolute top-1/2 left-5 -translate-y-1/2 z-10 pointer-events-none">
        <p className="prose-p2 whitespace-pre-line">
          <strong>{title}</strong>
        </p>
      </div>
      <div className="relative pointer-events-none max-w-[720px]">
        {/* 왼쪽 gradient가 필요한 경우에만 적용한다. */}
        {gradient && (
          <div
            className={clsx(
              gradient,
              'object-left',
              'absolute',
              'left-0',
              'w-full',
              'h-[88px]',
              'z-[5]',
            )}
          />
        )}
        <Image
          priority={true}
          src={imgSrc}
          alt={imgAlt}
          width={gradient ? 320 : 720}
          height={88}
          className={clsx(
            'absolute',
            'right-0',
            'h-[88px]',
            'object-cover',
            'object-right',
          )}
        />
      </div>
    </div>
  )
}

export default Banner
