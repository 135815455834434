import { MouseEvent } from 'react'
import { useSetAtom } from 'jotai'
import { KOR_CANCERS } from '@/consts'
import { mainAtom } from '@/stores'
import type { CancerType } from '@/types/recommend'
import eventEmitter, { EVENT } from '@/utils/eventEmitter'
import DropdownComponent from '@/v1/Dropdown'
import MaterialSymbol from '@/v1/MaterialSymbol'

const Dropdown = ({
  cancerType,
  setCancerType,
}: {
  cancerType: CancerType
  setCancerType: (cancerType: CancerType) => void
}) => {
  const setMainAtom = useSetAtom(mainAtom)

  const handleClick = (
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
  ) => {
    const cancerType = (event.currentTarget.ariaLabel ?? '전체') as CancerType
    setCancerType(cancerType)
    setMainAtom((prev) => ({ ...prev, cancerType }))
    eventEmitter.emit(EVENT.SET_MAIN_TOOLTIP_MANUALLY, false)
  }

  return (
    <DropdownComponent
      options={['전체', ...KOR_CANCERS]}
      value={cancerType}
      onClick={(event) => handleClick(event)}
      menuMarginTop={12}
      gaToggleSelector="main_cancertype_select"
      gaDropdownSelector="main_cancertype_dropdown"
    >
      <h3 className="flex items-center prose-h4">
        {cancerType}
        <MaterialSymbol name="expand_more" size={24} className="ml-1" />
      </h3>
    </DropdownComponent>
  )
}

export default Dropdown
