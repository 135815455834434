import { useEffect, useState } from 'react'
import { useSetAtom } from 'jotai'
import { mainAtom, MAIN_KEY, mainBottomSheetAtom } from '@/stores'
import type { Main } from '@/stores'
import { CancerType } from '@/types/recommend'
import { getSessionStorage } from '@/utils'
import eventEmitter, { EVENT } from '@/utils/eventEmitter'
import AutoHideTooltip from '@/v1/Tooltip/AutoHideTooltip'
import { mainAtomSchema } from '../types'

interface Props {
  cancerType: CancerType
}

const CONTENTS = {
  전체: '관심 있는 암을 선택해 보세요.',
  암종별: '건강 기록에서 선택한 암입니다.',
} as const

/**
 * 브라우저 세션 스토리지에 저장된 mainAtom에 hasMainViewed가 있는지 확인한다.
 * @returns hasMainViewed(메인 화면 방문 여부)가 있으면 true, 없으면 false
 */
const checkMainViewed = () => {
  // jotai atom으로 확인하지 않고 직접 세션 스토리지에 접근하여 확인하는 이유는
  // 컴포넌트가 리렌더링되면서 초기 상태로 설정된 후에, 세션 스토리지에서 값을 가져와 상태가 업데이트되기 때문.
  const mainAtom = getSessionStorage<Main | null>(MAIN_KEY, null)
  if (!mainAtom) return false

  try {
    const { hasMainViewed } = mainAtomSchema.parse(mainAtom)
    return hasMainViewed
  } catch (error) {
    return false
  }
}

/**
 * 암종 드롭다운의 우측에 보여질 툴팁
 * @param cancerType 드롭다운에 선택된 암종
 */
const Tooltip = ({ cancerType }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const setMainAtom = useSetAtom(mainAtom)
  const setMainBottomSheet = useSetAtom(mainBottomSheetAtom)

  // 브라우저 세션 1회 동안만 툴팁 및 바텀시트를 보여주기 위해, 첫 방문 시 세션 스토리지에 값 저장
  useEffect(() => {
    const hasMainViewed = checkMainViewed()
    if (hasMainViewed) return

    setMainAtom((prev) => ({ ...prev, hasMainViewed: true }))
    setShowTooltip(true)
    setMainBottomSheet(true)
  }, [showTooltip, setMainAtom, setMainBottomSheet])

  useEffect(() => {
    // 메인 암종 드롭다운 옆 툴팁 노출을 수동으로 관리
    // 1. 온보딩 프로세스 완료 후 툴팁 노출
    // 2. 드롭다운으로 암종 수정시 툴팁 제거
    const handleTooltip = (isShow: boolean) => {
      setShowTooltip(isShow)
    }

    eventEmitter.on(EVENT.SET_MAIN_TOOLTIP_MANUALLY, handleTooltip)

    return () => {
      eventEmitter.off(EVENT.SET_MAIN_TOOLTIP_MANUALLY, handleTooltip)
    }
  }, [])

  // 리스트 필터 암종이 '전체'인 경우는 '전체', 아니면 '암종별' 툴팁을 보여준다.
  const contentType = cancerType !== '전체' ? '암종별' : '전체'

  if (!showTooltip) return null

  return (
    <AutoHideTooltip
      placement="right"
      delay={10000}
      content={CONTENTS[contentType]}
      onHide={() => setShowTooltip(false)} // 툴팁이 사라질 때 상태 업데이트
    />
  )
}

export default Tooltip
