'use client'

import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { useSetAtom } from 'jotai'
import { S3_IMAGE_URL } from '@/config'
import { URL_PATH } from '@/consts'
import { useModal } from '@/hooks/useModal'
import { mainBottomSheetAtom } from '@/stores'
import Button from '@/v1/Button'
import MaterialSymbol from '@/v1/MaterialSymbol'

/**
 * 세션 기준 메인페이지에 최초 한 번 노출되는 AI 진료노트 바텀시트
 */
const MainBottomSheet = () => {
  const setMainBottomSheet = useSetAtom(mainBottomSheetAtom)
  const { push } = useRouter()
  const { bottomSheetModal } = useModal()

  const handleClose = () => {
    bottomSheetModal.hide()
    setMainBottomSheet(false)
  }

  const handleClick = () => {
    push(URL_PATH.AINotes)
    handleClose()
  }

  return (
    <>
      <div className="bg-aqua-100 rounded-t-[16px] w-full flex flex-col items-center pt-14 relative">
        <h4 className="prose-h4">진료 잘 다녀오셨어요?</h4>
        <p className="prose-p2 mt-2 mb-md">
          녹음한 진료 내용 AI가 정리해 드려요!
        </p>
        <Image
          src={`${S3_IMAGE_URL}/main_bottomsheet.png`}
          alt=""
          width={272}
          height={234}
        />
      </div>
      <button
        className="absolute top-3 right-3 p-3"
        data-ga="main_notes_close"
        onClick={handleClose}
        type="button"
      >
        <MaterialSymbol name="close" />
      </button>

      <div className="p-md">
        <Button size="large" data-ga="main_notes_cta" onClick={handleClick}>
          AI 진료 노트 시작하기
        </Button>
      </div>
    </>
  )
}

export default MainBottomSheet
