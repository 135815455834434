import { useRouter } from 'next/navigation'
import clsx from 'clsx'
import { URL_PATH } from '@/consts'
import useMyNotifications from '@/hooks/useMyNotifications'
import MaterialSymbol from '@/v1/MaterialSymbol'
import NewNoti from '@/v1/NewNoti'
import { Header } from '../Header'

const NotificationButton = () => {
  const { push } = useRouter()
  const { hasUnreadNotifications } = useMyNotifications()

  return (
    <span className={clsx('block', 'relative')}>
      <Header.IconButton
        data-ga="header_notifications"
        icon={<MaterialSymbol name="notifications" />}
        className="pl-2"
        onClick={() => {
          push(URL_PATH.MyNotifications)
        }}
      />
      <NewNoti
        className={clsx('top-[17px]', 'left-[26px]', {
          hidden: !hasUnreadNotifications,
        })}
      />
    </span>
  )
}

export default NotificationButton
