import Link from 'next/link'
import clsx from 'clsx'
import CustomChannelTalkButton from '@/components/CustomChannelTalkButton'
import { S3_IMAGE_URL } from '@/config'
import { COMPANY, URL_PATH } from '@/consts'

const Footer = () => {
  return (
    <footer
      className={clsx(
        'text-caption',
        'text-gray-70',
        'leading-5',
        'p-6',
        'bg-gray-20',
        'mt-12',
      )}
    >
      <img
        src={`${S3_IMAGE_URL}/lunit_care_logo_footer.svg`}
        alt="루닛케어"
        className="mb-5"
        width={146.12}
        height={22}
      />
      <div className="mb-[18px]">
        <Link href={URL_PATH.TermsService} className="underline">
          서비스 이용 약관
        </Link>
        <span> | </span>
        <Link href={URL_PATH.TermsPrivacy} className="text-gray-90 underline">
          <b>개인정보 처리방침</b>
        </Link>
      </div>
      <p>
        <b>{COMPANY.NAME} </b>대표이사 {COMPANY.CEO}
      </p>
      <p>
        <b>사업자등록번호 </b> {COMPANY.BUSINESS_NUMBER}
      </p>
      <address className="not-italic">
        <b>주소 </b>
        {COMPANY.ADDRESS}
      </address>
      <p>
        <b>메일 </b>
        <a href={`mailto:${COMPANY.EMAIL}`}>{COMPANY.EMAIL}</a>
      </p>
      <p>
        <b>전화 </b>
        <a href={`tel:${COMPANY.TELEPHONE}`}>{COMPANY.TELEPHONE}</a>
      </p>
      <p>
        <b>고객센터 </b>
        <CustomChannelTalkButton channelTalkLink="/users/me/?bot-type=1">
          <span className="underline">문의하기</span>
        </CustomChannelTalkButton>
      </p>
    </footer>
  )
}

export default Footer
