import { Swiper, SwiperSlide } from 'swiper/react'
import { SWIPER_SETTING } from '@/consts/swiperSetting'
import { QUICK_LINKS } from './consts'
import QuickLink from './QuickLink'

const QuickLinks = () => {
  const settings = {
    ...SWIPER_SETTING,
    slidesPerView: 1,
    spaceBetween: 12,
    slidesOffsetBefore: 24,
    slidesOffsetAfter: 24,
    breakpoints: {
      768: {
        slidesPerView: 4.3,
      },
      480: {
        slidesPerView: 2.5,
      },
      320: {
        slidesPerView: 1.8,
      },
    },
  }

  return (
    <div className="pt-1">
      <Swiper {...settings}>
        {QUICK_LINKS.map(({ icon, title, description, href }) => (
          <SwiperSlide key={`quicklink-${href}`}>
            <div className="pt-4 pb-5">
              <QuickLink
                icon={icon}
                title={title}
                description={description}
                href={href}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default QuickLinks
